<template>
    <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card tile class="grey darken-3">
            <v-app-bar dense color="blue-grey darken-2">
                <v-toolbar-title>Auswahl</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field flat solo dense hide-details label="Suche" prepend-inner-icon="mdi-magnify" background-color="blue-grey darken-3"
                    v-model="search"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                </v-btn>
            </v-app-bar>
            <v-card-text style="height: 300px;">
                <v-list nav dense class="grey darken-3">
                    <v-list-item-group v-model="selectedItem" color="blue-grey lighten-3">
                    <v-list-item v-for="(item) in filteredItems" :key="item.id" :value="item.id">
                        <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-divider />
            <v-card-actions>
            <v-btn text class="warning" @click="onCancel()">Abbrechen</v-btn>
            <v-spacer />
            <v-btn v-if="selectedItem" text class="success" @click="onSubmit()">Übernehmen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
	export default {
	  	name: 'DialogOne',
		components: {},
	  	props: {
	  		active: {
	  			type: Boolean,
	  			default: false,
                required: true,
	  		},
	  	},
		data: () => ({
            loading: false,
            dialog: false,
            search: null,
            selectedItem: null,
            items: [
                { id: 1, text: 'My Files', icon: 'mdi-folder' },
                { id: 8, text: 'Shared with me', icon: 'mdi-account-multiple' },
                { id: 3, text: 'Starred', icon: 'mdi-star' },
                { id: 4, text: 'Recent', icon: 'mdi-history' },
                { id: 5, text: 'Offline', icon: 'mdi-check-circle' },
                { id: 9, text: 'Uploads', icon: 'mdi-upload' },
                { id: 7, text: 'Backups', icon: 'mdi-cloud-upload' },
            ],
        }),
        computed: {
            filteredItems () {
                return this.items.filter(item => {
                    if(!this.search) return this.items
                    return (item.text.toLowerCase().includes(this.search.toLowerCase()))
                })
            },
        },
		methods: {
            onCancel() {
                this.$emit('cancel')
                this.dialog = false
            },
            onSubmit() {
                console.log('selected:', this.selectedItem)
                this.$emit('submit', this.selectedItem)
                this.dialog = false
            },
        },
		mounted() {
            console.log('dialog one mounted')
            this.dialog = true
        },
        beforeDestroy() {
            console.log('destroying dialog one')
        },
	  	watch: {

        },
    }
</script>
<style scoped>
</style>